import React from 'react';
import { Contact } from '../components/index';

function ContactUs(){
    return(
        <Contact/>

    )
}

export default ContactUs;